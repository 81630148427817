import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectBtsExtensionPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';
import { List, ListItem, ListItemText } from '../../components/List';
import { CheckIcon } from '../../components/FAIcons';
import { GatsbyImageLink } from '../../components/GatsbyImageLink';

type ClassKey = 'main' | 'imageContainer' | 'image';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
    imageContainer: { display: 'flex', justifyContent: 'center' },
    image: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
}));

export type TProjectBtsExtensionP = {
    data: GQLProjectBtsExtensionPageQuery;
};

function ProjectBtsExtension(props: TReactFCP<TProjectBtsExtensionP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml, watchdogLog }: GQLProjectBtsExtensionPageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectBtsExtensionPageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                <Link variant='external' to='https://breachteksolutions.com/'>BreachTek Solutions</Link> (BTS), is a
                IT security company building DNS-based Ad and Malware protection for consumers, small businesses, and
                enterprises. I was introduced to the company through one of my close friends at{` `}
                <Link variant='external' to='https://gtmotorsports.org/'>Gran Touring Motorsports</Link> who has been
                helping the founder build its technology from the beginning. One of the features they would like to
                offer their higher tier customers is a browser extension to provide protection while browsing the
                web. Given my expertise building web applications they brought me in to build it.
            </Typography>
            <Typography paragraph>
                Given that none of us had built a browser extension before the goal was to just build a proof of concept
                that could be replaced once we had design and features finalized. The criteria for success were
            </Typography>
            <List paragraph icon={{ Component: CheckIcon, props: { fixedWidth: true, size: 'xs' } }}>
                <ListItem>
                    <ListItemText
                        primary={`Works with every major browser`}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Can discover URLs placed on a webpage over time`} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Ability to generate a threat assessment of the currently viewed webpage`} />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={`Can display the number of threats on a page as a badge on the extension icon`}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`
                        Has an interactive window containing a detailed threat assessment of the current webpage
                    `} />
                </ListItem>
            </List>
            <GatsbyImageLink
                to={watchdogLog!.childImageSharp!.original!.src!}
                className={styles.imageContainer}
                disableUnderline
                imageProps={{
                    className: styles.image,
                    style: {
                        maxWidth: watchdogLog!.childImageSharp!.fluid!.presentationWidth!, height: 'auto', flex: 1
                    },
                    alt: 'The watchdog reporting and scoring URLs within a webpage',
                    fluid: watchdogLog!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='caption' color='light' align='center' paragraph>
                The watchdog reporting and scoring URLs within a webpage
            </Typography>
            <Typography paragraph>
                In the end it wasn't perfect, but I was able to complete each requirement. Each iframe has a script
                injected which scans and watches for new URLs inserted into the DOM. That script reports its
                findings to the extension which assesses each URL for threats and reports back to the script. The
                script could then block access to links, highlight risks, and more.
            </Typography>
            <Typography paragraph>
                The extension keeps a global threat assessment and uses it to generate a window to display the
                assessment. Assessments of URLs are currently randomly generated and assigned. In future versions, the
                URL scanning and detection would be more advanced, the extension would use BreachTek's technology to
                analyze threats, security policies for threats would be enforced, and the customer could manage
                their account directly from the extension.
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectBtsExtensionP>): TReactFCR => (
    <Page component={ProjectBtsExtension} cprops={props} />
);

export const query = graphql`
    query ProjectBtsExtensionPage {
        sitePage(internalComponentName: {eq: "ComponentProjectsBtsExtension"}) {
            path
        }
        projectsYaml(pid: {eq: "bts-extension"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
        watchdogLog: file(relativePath: {eq: "images/projects/bts-extension/watchdog-log.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                }
                original {
                    src
                }
            }
        }
    }
`;
